import React, { useEffect, useState } from 'react';
import api from '../../../api/axiosInstane';
import { FaTrophy, FaGamepad, FaChartLine, FaCoins, FaFire, FaSadTear, FaUserNinja } from 'react-icons/fa';

const StatCard = ({ icon, title, value, text }) => (
  <div className="bg-white text-green-900 p-6 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300">
    <div className="text-4xl mb-3 text-green-700">{icon}</div>
    <h3 className="text-2xl font-semibold">{title}</h3>
    <p className="text-4xl font-bold text-green-900 mt-2">{value}</p>
    <p className="text-sm opacity-75 mt-2">{text}</p>
  </div>
);

function PlayerStats() {
  const [playerStats, setPlayerStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPlayerStats = async () => {
      try {
        const response = await api.get('/api/games/player-stats');
        if (response.data.message === "Player not found") {
          setPlayerStats(null);
        } else {
          setPlayerStats(response.data);
        }
      } catch (err) {
        setError('Failed to fetch player stats');
      } finally {
        setLoading(false);
      }
    };

    fetchPlayerStats();
  }, []);

  if (loading || !playerStats) return null;
  if (error) return <p className="text-center text-red-500">{error}</p>;

  return (
    <div className="mt-12 bg-green-800 text-white rounded-3xl shadow-xl p-8">
      <h2 className="text-4xl font-bold text-center mb-4">🏆 Player Statistics</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 text-center">
        <StatCard icon={<FaGamepad />} title="Total Games Played" value={playerStats.totalGamesPlayed} text="Total games played in MLRC." />
        <StatCard icon={<FaTrophy />} title="Total Wins" value={playerStats.totalWins} text="Great job! Keep up the winning streak!" />
        <StatCard icon={<FaSadTear />} title="Total Losses" value={playerStats.totalLoss} text="Losses help you learn. Stay strong!" />
        <StatCard icon={<FaChartLine />} title="Win Rate" value={playerStats.winRate} text="Your current win rate." />
        <StatCard icon={<FaFire />} title="Highest Win Streak" value={playerStats.highestWinStake} text="Your best consecutive wins!" />
        <StatCard icon={<FaSadTear />} title="Longest Losing Streak" value={playerStats.longestLosingStreak || 0} text="Tough luck! Bounce back stronger!" />
        <StatCard icon={<FaCoins />} title="Total Points" value={playerStats.totalPoints} text="Total points earned in MLRC." />
        <StatCard icon={<FaCoins />} title="Total Rounds Played" value={playerStats.totalRoundsPlayed} text="Total rounds you've participated in." />
        <StatCard icon={<FaUserNinja />} title="Biggest Rival" value={playerStats.biggestRival || 'N/A'} text="The player who has defeated you the most!" />
      </div>
    </div>
  );
}

export default PlayerStats;
