import React from 'react';
import hideden_card from '../../../assets/images/card5.png';

export default function Player({ id, name, status, cards, style }) {
  // Decide the background color based on status text
  const statusBg = status.includes('chips') ? 'bg-[#efd3ab]' : 'bg-yellow-400';

  return (
    <div className="absolute flex flex-col items-center" style={style}>
      {/* Cards */}
      <div className="flex gap-[clamp(2px,0.5vw,4px)] mb-2">
        {cards.map((card, i) => (
          <img
            key={i}
            src={card === 'hidden' ? hideden_card : card}
            alt={`Player Card ${i + 1}`}
            className="w-[clamp(40px,5vw,80px)] h-[clamp(55px,6vw,100px)] object-contain"
          />
        ))}
      </div>
      {/* Player Info */}
      <div className="flex flex-col items-center text-center">
        <div className="bg-black text-white border border-green-500 
                px-2 py-1
                rounded 
                text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl 
                mb-1
                min-h-[20px] sm:min-h-[24px] md:min-h-[28px] lg:min-h-[32px] xl:min-h-[36px]">
          {id}
        </div>


        <div className="bg-[#00d02b] text-black rounded mb-1
                        px-[clamp(4px,1vw,8px)] py-[clamp(2px,0.5vw,4px)]
                        text-[clamp(8px,2vw,14px)]">
          {name}
        </div>
        <div className={`${statusBg} text-black rounded
                        px-[clamp(4px,1vw,8px)] py-[clamp(2px,0.5vw,4px)]
                        text-[clamp(8px,2vw,12px)]`}>
          {status}
        </div>
      </div>
    </div>
  );
}
