// App.js
import React, { useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RegisterPage from './modules/authentication/register/RegistrationPage';
import LoginPage from './modules/authentication/login/LoginPage';
import Activate from './modules/authentication/Activate/Activate';
import PaymentPage from './modules/payingpage/PaymentPage';
import PhoneVerification from './modules/authentication/PhoneVerification/PhoneVerification';
import PrivateRoute from './components/PrivateRoutes';
import AuthSuccessPage from './modules/authentication/authSuccessPage/AuthSuccessPage';
import Dashboard from './modules/dashboard/Dashboard';
import GameInfo from './modules/gameInfoPage/GamePage';
import HomePage from './modules/homePage/HomePage';
import Profile from './modules/profile/Profile';
import CreateGame from './modules/createGame/CreateGame';
import GamePage from './modules/gamePage/GamePage';
import CommunityRoles from './modules/CommunityRoles/CommunityRoles';

// Import wallet adapter providers and UI components
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import Game from './modules/testgamepage/game'
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import '@solana/wallet-adapter-react-ui/styles.css';

function App() {
  // Set the Solana network (change to 'devnet' for testing if needed)
  const endpoint = useMemo(() => 'https://api.devnet.solana.com', []);

  // Configure the wallets you want to support (more wallets can be added here)
  const wallets = useMemo(() => [new PhantomWalletAdapter()], []);

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <Router>
            {/* Global Wallet Connect Button */}
              {/* <div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 1000 }}>
                <WalletMultiButton />
              </div> */}
            <Routes>
              <Route element={<PrivateRoute />}>
                <Route path="/validate-otp" element={<PhoneVerification />} />
                <Route path="/create-game" element={<CreateGame />} />
                <Route path="/game/:game_id" element={<GamePage />} />
                <Route path="/edit-profile" element={<Profile />} />
                <Route path="/game-details/:game_id" element={<GameInfo />} />
                
              </Route>
              <Route>
              <Route path="/game" element={< Game/>} />
                <Route path="/create-game" element={<CreateGame />} />
                <Route path="/payment" element={<PaymentPage />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/" element={<HomePage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/user/activate/:token" element={<Activate />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/auth-success" element={<AuthSuccessPage />} />
                <Route path="/community-roles" element={<CommunityRoles />} />
              </Route>
            </Routes>
          </Router>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
