import React from 'react';
import hideden_card from '../../../assets/images/card5.png';

export default function HiddenThreeCards() {
  return (

    <div className="absolute sm:top-[20%] sm:left-[75%] top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2">
      <div className="relative w-[clamp(50px,5vw,80px)] h-[clamp(75px,7.5vw,120px)]">
        {/* Card 1 */}
        <div
          className="w-full h-full absolute top-0 left-0"
          style={{ transform: 'rotate(40deg)' }}
        >
          <img
            src={hideden_card}
            alt="Hidden Card 1"
            className="w-full h-full object-contain rounded"
          />
        </div>


        <div
          className="w-full h-full absolute top-0 sm:left-12 left-3"
          style={{ transform: 'rotate(40deg)' }}
        >
          <img
            src={hideden_card}
            alt="Hidden Card 2"
            className="w-full h-full object-contain rounded"
          />
        </div>

        <div
          className="w-full h-full absolute top-0 sm:left-24 left-6"
          style={{ transform: 'rotate(40deg)' }}
        >
          <img
            src={hideden_card}
            alt="Hidden Card 3"
            className="w-full h-full object-contain rounded"
          />
        </div>
      </div>
    </div>
  );
}
