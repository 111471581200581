import React from 'react';

export default function ActionButtons({ onCheck, onFold, onRaise }) {
  // Default Raise handler as in the original code
  const handleRaise = () => {
    const raiseInput = prompt('Enter your raise amount:');
    if (raiseInput !== null && raiseInput.trim() !== '') {
      const amount = Number(raiseInput);
      if (!isNaN(amount) && amount > 0) {
        alert(`You raised ${amount} chips! (Implement your real logic here)`);
      } else {
        alert('Invalid raise amount!');
      }
    }
  };

  return (
    <div className="fixed bottom-0 left-0 w-full 
                    flex justify-center gap-4 
                    py-4 bg-[#024A04]">
      <button
        onClick={onCheck || (() => alert("Check action triggered"))}
        className="text-white font-bold border border-white rounded-md
                   shadow-[0_0_8px_var(--tw-shadow-color)]
                   bg-[#006445]
                   px-[clamp(10px,4vw,50px)] py-[clamp(4px,1vw,8px)]
                   text-[clamp(12px,3vw,16px)]
                   transition-all duration-300
                   hover:ring-4 hover:ring-[#006445] hover:brightness-110
                   active:brightness-90"
        style={{ '--tw-shadow-color': '#006445' }}
      >
        Check
      </button>

      <button
        onClick={onFold || (() => alert("Fold action triggered"))}
        className="text-white font-bold border border-white rounded-md
                   shadow-[0_0_8px_var(--tw-shadow-color)]
                   bg-[#004ece]
                   px-[clamp(10px,4vw,50px)] py-[clamp(4px,1vw,8px)]
                   text-[clamp(12px,3vw,16px)]
                   transition-all duration-300
                   hover:ring-4 hover:ring-[#004ece] hover:brightness-110
                   active:brightness-90"
        style={{ '--tw-shadow-color': '#004ece' }}
      >
        Fold
      </button>

      <button
        onClick={onRaise || handleRaise}
        className="text-white font-bold border border-white rounded-md
                   shadow-[0_0_8px_var(--tw-shadow-color)]
                   bg-[#ff6d00]
                   px-[clamp(10px,4vw,50px)] py-[clamp(4px,1vw,8px)]
                   text-[clamp(12px,3vw,16px)]
                   transition-all duration-300
                   hover:ring-4 hover:ring-[#ff6d00] hover:brightness-110
                   active:brightness-90"
        style={{ '--tw-shadow-color': '#ff6d00' }}
      >
        Raise
      </button>
    </div>
  );
}
