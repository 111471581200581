import React, { useEffect, useState } from 'react';
import Player from './components/PlayerSeat';
import ActionButtons from './components/ActionButtons';
import PotDisplay from './components/PotDisplay';
import CommunityCards from './components/CommunityCards';
import HiddenThreeCards from './components/HiddenThreeCards';


// Create an array of 52 card images as before.
const cardImages = Array.from({ length: 52 }, (_, i) =>
  require(`../../assets/images/cards/${i + 1}.png`)
);

export default function PokerTable() {
  const [isPortrait, setIsPortrait] = useState(false);

  useEffect(() => {
    const checkOrientation = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
    };
    checkOrientation();
    window.addEventListener('resize', checkOrientation);
    return () => window.removeEventListener('resize', checkOrientation);
  }, []);

  // Example card subsets
  const communityCards = cardImages.slice(0, 5);
  const bottomCards = cardImages.slice(9, 11);

  // Example pot amount
  const potAmount = 24;

  return (
    <div className="w-screen h-screen bg-[#024A04] overflow-hidden flex items-center justify-center relative">
      {/* Rotating wrapper for portrait orientation */}
      <div
        className={`
          transition-transform duration-500 ease-in-out
          ${isPortrait ? 'rotate-90 origin-center scale-[1.1]' : ''}
        `}
        style={{
          width: isPortrait ? '140vw' : '100vw',
          height: isPortrait ? '100vh' : 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {/* Table */}
        <div
          className="relative w-[90vw] max-w-[1400px] aspect-[1400/640] rounded-full overflow-visible shadow-2xl"
          style={{
            background: 'radial-gradient(circle at center, #037a03 0%, #016101 85%)'
          }}
        >
          {/* Table Borders */}
          <div className="absolute inset-0 rounded-full border-[clamp(4px,0.6vw,10px)] border-[#6b4a1e] pointer-events-none" />
          <div className="absolute inset-[2.5vw] rounded-full border-[clamp(1px,0.2vw,4px)] border-green-400 pointer-events-none" />

          {/* POT DISPLAY */}
          <PotDisplay potAmount={potAmount} />

          {/* COMMUNITY CARDS */}
          <CommunityCards communityCards={communityCards} />

          <HiddenThreeCards />

          {/* Players */}
          <Player 
            id="9" 
            name="Mohammed" 
            status="Fold" 
            cards={['hidden', 'hidden']} 
            style={{ top: '55%', left: '-4vw', transform: 'translateY(-50%)' }}
          />
          <Player 
            id="9" 
            name="Mohammed" 
            status="Call: 10 chips" 
            cards={['hidden', 'hidden']} 
            style={{ top: '55%', right: '-4vw', transform: 'translateY(-50%)' }}
          />
          <Player 
            id="1" 
            name="Mohammed" 
            status="Check" 
            cards={['hidden', 'hidden']} 
            style={{ top: '-4vw', left: '50%', transform: 'translateX(-50%)' }}
          />
          <Player 
            id="11" 
            name="Mohammed (Dealer)" 
            status="Raise: 10 chips" 
            cards={bottomCards} 
            style={{ bottom: '-3vw', left: '50%', transform: 'translateX(-50%)' }}
          />

        </div>
      </div>

      {/* Action Buttons */}
      <ActionButtons />
    </div>
  );
}
