import React, { useEffect, useState, useMemo } from 'react';
import api from '../../../api/axiosInstane';

const GamePlayAnnouncements = () => {
  const [roundData, setRoundData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRoundData = async () => {
      try {
        const response = await api.get('/api/games/get-last-round-info');
        setRoundData(response.data);
      } catch (error) {
        console.error('Error fetching round data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRoundData();
  }, []);

  // Background gradients and animations
  const gradients = [
    "bg-gradient-to-br from-purple-600 via-pink-500 to-red-500",
    "bg-gradient-to-br from-blue-500 via-indigo-500 to-purple-600",
    "bg-gradient-to-br from-green-500 via-teal-400 to-blue-500",
    "bg-gradient-to-br from-yellow-500 via-orange-400 to-red-500",
  ];
  const randomGradient = useMemo(() => gradients[Math.floor(Math.random() * gradients.length)], []);

  const animations = ["animate-bounce", "animate-pulse", "animate-wiggle"];
  const bannerAnimation = useMemo(() => animations[Math.floor(Math.random() * animations.length)], []);

  if (loading) {
    return <p className="text-center text-lg font-bold">Loading...</p>;
  }

  if (!roundData) {
    return <p className="text-center text-lg font-bold text-red-500">Failed to load round data.</p>;
  }

  return (
    <section className="relative overflow-hidden mt-8">
      {/* Background Animated Overlay */}
      <div className="absolute inset-0 flex justify-center items-center pointer-events-none">
        <div className="w-full h-full opacity-20 animate-spin-slow" style={{
          background: "radial-gradient(circle, rgba(255,255,255,0.3), transparent)",
        }}></div>
      </div>

      <div className={`relative ${randomGradient} p-8 rounded-xl shadow-2xl overflow-hidden`}>
        {/* Announcement Header */}
        <h1 className={`text-center text-3xl font-extrabold mb-6 neon-text ${bannerAnimation}`}>
          {roundData.message}
        </h1>

        {/* Community Cards */}
        <div className="flex justify-center space-x-4 mb-8">
          {[
            ...roundData.community_cards.flop,
            roundData.community_cards.turn,
            roundData.community_cards.river,
          ].map((cardNumber, index) => (
            <img
              key={index}
              src={require(`../../../assets/images/cards/${cardNumber}.png`)}
              alt={`Community Card ${index + 1}`}
              className="w-20 h-28 rounded-lg shadow-lg transform hover:scale-110 transition duration-300"
            />
          ))}
        </div>

        {/* Player Cards Grid */}
        <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-${roundData.players.length} gap-6`}>
          {roundData.players.map((player, index) => (
            <div
              key={index}
              className={`flex flex-col items-center p-4 rounded-xl shadow-lg border ${
                player.rank === 1 ? "border-yellow-400" : "border-transparent"
              } transform transition duration-300 hover:scale-105 opacity-0 fade-in`}
              style={{ animationDelay: `${index * 0.2}s` }}
            >
              <img src={player.user_image} alt={player.username} className="w-16 h-16 rounded-full mb-2" />
              <p className="text-xl font-bold mb-2">{player.username}</p>
              <div className="flex space-x-2">
                {player.cards.map((cardNumber, i) => (
                  <img
                    key={i}
                    src={require(`../../../assets/images/cards/${cardNumber}.png`)}
                    alt="Card"
                    className="w-16 h-24 rounded-lg shadow transform hover:scale-110 transition duration-300"
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Inline Styles for Custom Animations */}
      <style jsx>{`
        @keyframes wiggle {
          0%, 100% { transform: rotate(-3deg); }
          50% { transform: rotate(3deg); }
        }
        .animate-wiggle { animation: wiggle 1s ease-in-out infinite; }
        @keyframes spin {
          from { transform: rotate(0deg); }
          to { transform: rotate(360deg); }
        }
        .animate-spin-slow { animation: spin 8s linear infinite; }
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(20px); }
          to { opacity: 1; transform: translateY(0); }
        }
        .fade-in { animation: fadeIn 0.5s ease-out forwards; }
        .neon-text {
          text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #f0f, 0 0 30px #f0f, 0 0 40px #f0f, 0 0 50px #f0f;
        }
      `}</style>
    </section>
  );
};

export default GamePlayAnnouncements;
