import React from "react";
import { motion } from "framer-motion";
import Header from '../../components/header/Header';
import Footer from '../../components/Footer/Footer';
const terms = [
  { article: 1, title: "Purpose and Acceptance", content: "By accessing MyLittleRiver, you fully accept these GTU." },
  { article: 2, title: "Game Description", content: "An educational Texas Hold’em poker game to improve strategy without real money." },
  { article: 3, title: "Texas Hold’em Rules", content: "Each player receives 2 private cards. The best 5-card hand wins the round." },
  { article: 4, title: "MyLittleRiverCard Rapido", content: "A fast-paced variant where players accumulate points and win by reaching the target score." },
  { article: 5, title: "Pre-recorded Scenarios", content: "15,000 pre-recorded games with a learning system to enhance player strategies." },
  { article: 6, title: "Access & Payment", content: "Free demo available. Full access requires a subscription. 20% discount with MLRCOIN-O." },
  { article: 7, title: "NFT Collection", content: "Purchase NFTs representing card hands, with value based on hand strength." },
  { article: 8, title: "User Registration", content: "Accounts are required. Users must keep credentials secure." },
  { article: 9, title: "Intellectual Property", content: "All content belongs exclusively to MyLittleRiver." },
  { article: 10, title: "User Behavior", content: "Respect is mandatory. Fraud and abuse are strictly prohibited." },
  { article: 11, title: "Liability Limitations", content: "MyLittleRiver is not liable for any damages from use or inability to use the game." },
  { article: 12, title: "GTU Updates", content: "Terms can change at any time, with updates on the official website." },
  { article: 13, title: "Personal Data", content: "Data is processed according to our Privacy Policy." },
  { article: 14, title: "Account Termination", content: "Accounts violating GTU can be suspended or terminated without notice." },
  { article: 15, title: "Governing Law", content: "GTU are governed by French law. Disputes go to French courts." }
];

const GTUPage = () => {
  return (
    <div>
                      <Header />

    <div className="max-w-5xl mx-auto py-12 px-6">
      <h1 className="text-4xl font-bold text-center text-green-700 mb-6">📜 Terms of Service</h1>
      <p className="text-center text-gray-700 mb-12">
        Please review our terms before using MyLittleRiver.
      </p>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
        {terms.map((term) => (
          <motion.div
            key={term.article}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: term.article * 0.05 }}
            className="p-6 bg-white shadow-lg rounded-lg border-l-4 border-green-600"
          >
            <h2 className="text-xl font-semibold text-green-800">📌 {term.title}</h2>
            <p className="text-gray-700 mt-2">{term.content}</p>
          </motion.div>
        ))}
      </div>
    </div>
    <Footer/>
    </div>

  );
};

export default GTUPage;
