import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { placeBet } from '../services/websocket';
import decisionTicker from '../../../assets/sounds/decision_ticker.wav';

function ActionButtons({ game_id }) {
  // Get the game state from Redux, and provide a fallback if state.game is null.
  const gameState = useSelector((state) => state.game) || {};
  const { status, nextPlayer, myInfo } = gameState;
  // Use a safe fallback in case myInfo is null.
  const safeMyInfo = myInfo || {};

  const [isRaise, setIsRaise] = useState(false);
  const [raiseAmount, setRaiseAmount] = useState(0);
  const [timeLeft, setTimeLeft] = useState(20);

  // Callback to handle fold action with a safeguard check for player_id.
  const handleFoldAction = useCallback(() => {
    if (!safeMyInfo.player_id) return; // Prevent action if player_id is missing
    placeBet(game_id, safeMyInfo.player_id, 0, 'fold');
    setIsRaise(false);
    setTimeLeft(20);
  }, [game_id, safeMyInfo.player_id]);

  // Timer effect that automatically folds when time runs out.
  useEffect(() => {
    if (
      status !== 'playing' ||
      !nextPlayer ||
      nextPlayer.player_id !== safeMyInfo.player_id
    )
      return;

    const timerInterval = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev === 6) {
          new Audio(decisionTicker).play();
        }
        if (prev <= 1) {
          clearInterval(timerInterval);
          handleFoldAction();
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [status, nextPlayer, safeMyInfo, handleFoldAction]);

  // Generic action handler for CALL/CHECK, FOLD, and RAISE.
  const handleAction = (betType) => {
    if (!safeMyInfo.player_id) return; // Prevent action if player_id is missing

    const betAmount =
      betType === 'RAISE'
        ? raiseAmount
        : betType === 'CALL'
        ? nextPlayer.bet_amount
        : 0;

    placeBet(game_id, safeMyInfo.player_id, betAmount, betType.toLowerCase());
    setIsRaise(false);
    setTimeLeft(20);
  };

  const handleRaiseClick = () => {
    setIsRaise(true);
    setRaiseAmount(0);
  };

  // Do not render buttons if the game isn't in a playing state or it's not this player's turn.
  if (
    status !== 'playing' ||
    !nextPlayer ||
    nextPlayer.player_id !== safeMyInfo.player_id
  )
    return null;

  // Tailwind styles for each button.
  const buttonStyles = {
    call: `
      bg-gradient-to-b from-green-600 to-green-900
      text-white font-semibold 
      px-4 py-2 min-w-[120px] rounded-md shadow-md border-2 border-white
      flex items-center justify-center 
      transition-all duration-300 
      hover:brightness-125 hover:scale-105 
      hover:shadow-lg hover:shadow-white
      cursor-pointer
    `,
    fold: `
      bg-gradient-to-b from-blue-400 to-blue-600
      text-white font-semibold 
      px-4 py-2 min-w-[120px] rounded-md shadow-md border-2 border-white
      flex items-center justify-center 
      transition-all duration-300 
      hover:brightness-125 hover:scale-105 
      hover:shadow-lg hover:shadow-white
      cursor-pointer
    `,
    raise: `
      bg-gradient-to-b from-orange-400 to-orange-600
      text-white font-semibold 
      px-4 py-2 min-w-[120px] rounded-md shadow-md border-2 border-white
      flex items-center justify-center 
      transition-all duration-300 
      hover:brightness-125 hover:scale-105 
      hover:shadow-lg hover:shadow-white
      cursor-pointer
    `
  };

  // Small CSS arrow inside a circle.
  const ArrowCircle = () => (
    <span className="flex items-center justify-center w-6 h-6 rounded-full border-2 border-white bg-white/20">
      <span className="w-2 h-2 border-y-2 border-y-transparent border-l-4 border-l-white bg-white" />
    </span>
  );

  return (
    <div className="absolute bottom-4 flex flex-col gap-3 sm:gap-4">
      <div className="flex gap-3">
        {/* CALL / CHECK button */}
        <button
          onClick={() =>
            handleAction(nextPlayer.bet_amount === '0' ? 'CHECK' : 'CALL')
          }
          className={buttonStyles.call}
        >
          <span className="mr-3">
            {nextPlayer.bet_amount === '0'
              ? 'CHECK'
              : `CALL ${nextPlayer.bet_amount}`}
          </span>
          <ArrowCircle />
        </button>

        {/* FOLD button */}
        <button onClick={handleFoldAction} className={buttonStyles.fold}>
          <span className="mr-4">FOLD</span>
          <ArrowCircle />
        </button>

        {/* RAISE button */}
        <button onClick={handleRaiseClick} className={buttonStyles.raise}>
          <span className="mr-2">RAISE</span>
          <ArrowCircle />
        </button>
      </div>

      {/* Timer Display */}
      <span className="text-sm font-semibold text-[#1c1c1c] mt-1">
        Time Left: {timeLeft}s
      </span>

      {/* Slider for raising amount */}
      {isRaise && (
        <div className="flex flex-col items-center mt-3">
          <input
            type="range"
            min="0"
            max={safeMyInfo.available_chips || 0}
            value={raiseAmount}
            onChange={(e) => setRaiseAmount(Number(e.target.value))}
            className="w-full sm:w-3/4 h-2 bg-gray-300 rounded-lg appearance-none cursor-pointer"
          />
          <span className="text-sm font-semibold text-[#1c1c1c] mt-1">
            Raise Amount: {raiseAmount} chips
          </span>
          <button
            onClick={() => handleAction('RAISE')}
            className="
              bg-green-500 text-white px-4 py-2 
              rounded-md shadow-md 
              hover:bg-green-700 transition-colors duration-300
            "
          >
            Confirm Raise
          </button>
        </div>
      )}
    </div>
  );
}

export default ActionButtons;
