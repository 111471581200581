import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/header/Header';
import Footer from '../../components/Footer/Footer';
import AvailableGames from './components/AvailableGames';
import GameActions from './components/GameActions';
import MobileVerification from './components/MobileVerification';
import MLRCoinSection from './components/MLRCoinSection';
import PlayerStats from './components/PlayerStats';
import GamePlayAnnouncements from './components/GamePlayAnnouncements';
import api from '../../api/axiosInstane';

function Dashboard() {
  const [games, setGames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMobileNumber, setHasMobileNumber] = useState(true);
  const [biggestRival, setBiggestRival] = useState("Unknown Player"); // Placeholder for now
  const navigate = useNavigate();

  // Dummy Player Stats (Replace with API data later)
  const playerStats = {
    totalGames: 245,
    totalWins: 140,
    totalLosses: 105,
    winRate: ((140 / 245) * 100).toFixed(1) + '%',
    highestStreak: 8,
    longestLosingStreak: 6,
    totalEarnings: '$7,890',
    lastBigWin: '$1,500',
  };

  // Custom message based on performance
  const performanceMessage =
    playerStats.highestStreak >= 100
      ? "🔥 You're on fire! Keep the streak going!"
      : playerStats.totalWins > playerStats.totalLosses
        ? '💪 Solid performance! Keep climbing!'
        : '😞 A few losses, but every game is a chance to turn things around!';

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      const fetchGames = async () => {
        try {
          const response = await api.get('/api/games/my-games');
          setGames(response.data);
        } catch (error) {
          console.error('Error fetching games:', error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchGames();

      const checkMobileNumber = async () => {
        try {
          const response = await api.get('/api/auth/check-mobile-number', {
            headers: { Authorization: `Bearer ${token}` },
          });
          setHasMobileNumber(response.data.hasMobileNumber);
        } catch (error) {
          console.error('Error checking mobile number:', error);
        }
      };
      checkMobileNumber();
    }
  }, [navigate]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-900 to-green-700 text-white">
      <Header />
      <div className="p-6 max-w-7xl mx-auto">
        <h1 className="text-4xl font-extrabold mb-6 text-center">Welcome to the MLRC</h1>

        {/* Mobile Verification Section */}
        {!hasMobileNumber && <MobileVerification />}

        {/* Game Actions Section */}
        <GameActions />
        {/* Combined Game Play & Winner Announcements Component */}
        <GamePlayAnnouncements />

        {/* MLRcoin Section */}
        <MLRCoinSection />

        {/* Available Games Section */}
        <AvailableGames games={games} isLoading={isLoading} />

        {/* Player Statistics Section */}
        <PlayerStats
          playerStats={playerStats}
          performanceMessage={performanceMessage}
          biggestRival={biggestRival}
        />

      </div>
      <Footer />
    </div>
  );
}

export default Dashboard;
