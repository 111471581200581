import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import InvitePlayersModal from '../../gamePage/components/InvitePlayersModal';
import SetTimeModal from './SetTimeModal';

const AvailableGames = ({ games, isLoading }) => {
  const navigate = useNavigate();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isSetTimeModalOpen, setIsSetTimeModalOpen] = useState(false);
  const [selectedGameId, setSelectedGameId] = useState(null);
  const [gameInviteLink, setGameInviteLink] = useState('');

  // Map game names to images
  const getGameImage = (gameName) => {
    const formattedName = gameName.toLowerCase().replace(/ /g, '_');
    try {
      return require(`../../../assets/images/${formattedName}.png`);
    } catch (error) {
      console.error(`Image for ${gameName} not found`, error);
      return null;
    }
  };

  // Format "Last Play" time
  const formatLastPlayTime = (lastActiveTime) => {
    return formatDistanceToNow(new Date(lastActiveTime), { addSuffix: true });
  };

  // Handle "Start" button click
  const handleStartGame = (gameId) => {
    navigate(`/game/${gameId}`);
  };

  // Handle "Invite" button click
  const handleInvite = (gameId) => {
    const inviteLink = `https://www.mylittlerivercard.com/game-details/${gameId}`;
    setGameInviteLink(inviteLink);
    setIsInviteModalOpen(true);
  };

  // Handle "Set Time" button click
  const handleSetTime = (gameId) => {
    setSelectedGameId(gameId);
    setIsSetTimeModalOpen(true);
  };

  // Save the selected time (from SetTimeModal)
  const handleSaveTime = (gameId, selectedTime) => {
    console.log(`Setting time for game ${gameId} to ${selectedTime}`);
    // Perform API call or other logic here
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="flex flex-col items-center space-y-4">
          <div className="w-16 h-16 border-8 border-t-8 border-green-500 border-solid rounded-full animate-spin"></div>
          <p className="text-lg font-semibold text-gray-200">Loading available games...</p>
        </div>
      </div>
    );
  }

  if (games.length === 0) {
    return (
      <div className="text-center text-gray-400 py-8">
        <p className="text-xl font-bold mb-4">No games available at the moment.</p>
        <p className="text-lg">
          It looks like there are no ongoing games right now. Be the first to set up a new game and invite your friends!
        </p>
        <button
          onClick={() => (window.location.href = '/create-game')}
          className="mt-4 bg-green-700 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg shadow-lg"
        >
          Create a Game
        </button>
      </div>
    );
  }

  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8 px-4">
      {games.map((game) => (
        <div
          key={game.id}
          className="
            bg-[#7ac810]
            w-full
            border-4 border-lime-500
            rounded-2xl
            shadow-xl
            text-center
            p-6
          "
        >
          {/* Top portion: game image */}
          <div className="mb-4">
            <img
              src={getGameImage(game.game_name)}
              alt={game.game_name}
              className="mx-auto w-auto h-auto rounded-md"
            />
          </div>

          {/* Game name */}
          <h2 className="text-2xl font-bold text-black mb-3">
            {game.game_name}
          </h2>

          {/* Game details */}
          <p className="text-black text-base mb-1">
            Status : <span className="font-semibold">{game.status}</span>
          </p>
          <p className="text-black text-base mb-1">
            Current Round : <span className="font-semibold">{game.current_round}</span>
          </p>
          <p className="text-black text-base mb-4">
            Last Play :{' '}
            <span className="font-semibold">
              {formatLastPlayTime(game.last_active_time)}
            </span>
          </p>

          {/* Buttons row */}
          <div className="flex justify-center space-x-1">
            {/* "Set Time" Button (red) */}
            <button
              onClick={() => handleSetTime(game.game_id)}
              className="
              w-32
              h-10
              bg-gradient-to-b from-[#c90000] to-[#c90000]
              text-white font-semibold
              text-sm
              py-1 px-4
              border-2 border-white
              rounded-md
              shadow
              brightness-200
              hover:from-red-400 hover:to-red-600
              hover:brightness-125 hover:scale-105 
              hover:shadow-[0_0_10px_5px_rgba(20,179,255,0.7)]
              focus:outline-none
              active:scale-95
              "
            >
              Set Time
            </button>

            {/* "invite" Button (purple) */}
            <button
              onClick={() => handleInvite(game.game_id)}
              className="
                w-32
                h-10
                bg-gradient-to-b from-[#7b00ff] to-[#7b00ff]
                text-white font-semibold
                text-base
                py-1 px-4
                brightness-150
                border-2 border-white
                rounded-md
                shadow
                hover:from-purple-400 hover:to-purple-600
                hover:shadow-[0_0_10px_5px_rgba(20,179,255,0.7)]
                hover:brightness-125 hover:scale-105 
                focus:outline-none
                active:scale-95
              "
            >
              invite
            </button>

            {/* "Start" Button (blue) */}
            <button
              onClick={() => handleStartGame(game.game_id)}
              className="
                  w-32
                  h-10                 
                  bg-gradient-to-b from-[#12a3ff] to-[#0f8bd9]
                  text-white font-semibold
                  text-base
                  py-1 px-4
                  border-2 border-white
                  rounded-md
                  brightness-100
                  shadow
                  hover:from-[#14b3ff] hover:to-[#1199ef]
                  hover:brightness-125 hover:scale-105 
                  hover:shadow-[0_0_10px_5px_rgba(20,179,255,0.7)]
                  focus:outline-none
                  active:scale-95
              "
            >
              Start
            </button>
          </div>
        </div>
      ))}

      {/* Invite Players Modal */}
      <InvitePlayersModal
        isOpen={isInviteModalOpen}
        onClose={() => setIsInviteModalOpen(false)}
        gameInviteLink={gameInviteLink}
      />

      {/* Set Time Modal */}
      <SetTimeModal
        isOpen={isSetTimeModalOpen}
        onClose={() => setIsSetTimeModalOpen(false)}
        onSave={handleSaveTime}
        gameId={selectedGameId}
      />
    </div>
  );
};

export default AvailableGames;


//hel