import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlusCircle } from 'react-icons/fa';

function GameActions() {
  const [gameId, setGameId] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleJoinGame = () => {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

    if (!gameId || gameId.length < 36 || !uuidRegex.test(gameId)) {
      setError('Invalid Game ID format');
      return;
    }

    try {
      navigate(`/game-details/${gameId}`);
    } catch (error) {
      setError('Game not found. Please check the Game ID and try again.');
    }
  };

  const handleCreateGame = () => {
    navigate('/create-game');
  };

  return (
    <div className="bg-gray-100 text-gray-800 rounded-lg shadow-lg p-6 mb-8">
      <h2 className="text-2xl font-bold mb-4 text-center">Create or Join a Game</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Create a Game */}
        <div
          onClick={handleCreateGame}
          className="bg-gradient-to-r from-green-600 to-green-400 p-6 rounded-lg shadow-lg text-center transform hover:scale-105 transition duration-300 ease-in-out cursor-pointer"
        >
          <FaPlusCircle className="text-4xl text-white mb-4 mx-auto" />
          <h3 className="text-xl font-bold mb-2 text-white">Create a Game</h3>
          <p className="text-white">Join in on the fun and create a game of your choice!</p>
        </div>

        {/* Join a Game */}
        <div className="bg-gradient-to-r from-green-600 to-green-400 p-6 rounded-lg shadow-lg text-center">
          <h3 className="text-xl font-bold mb-2 text-white">Join a Game</h3>
          <input
            type="text"
            placeholder="Enter Game ID"
            value={gameId}
            onChange={(e) => setGameId(e.target.value)}
            className="border border-gray-300 rounded-lg py-2 px-4 mb-4 w-full"
          />
          <button
            onClick={handleJoinGame}
            className="bg-white text-green-700 font-bold py-2 px-4 rounded-lg shadow-lg hover:bg-gray-200 transition duration-300 ease-in-out w-full"
          >
            Join Game
          </button>
          {error && <p className="text-red-600 mt-2">{error}</p>}
        </div>
      </div>
    </div>
  );
}

export default GameActions;
