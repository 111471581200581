import React from 'react';
import coin from '../../../assets/images/chip2.png';

export default function PotDisplay({ potAmount }) {
  return (
    <div
      className="absolute flex items-center justify-center gap-[clamp(4px,1vw,10px)]
                 px-[clamp(8px,2vw,16px)] py-[clamp(4px,1vw,8px)]
                 text-white text-[clamp(12px,3vw,24px)] font-bold
                 bg-[#44350c] rounded-md"
      style={{
        top: '60%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    >
      <img
        src={coin}
        alt="Pot Chip"
        className="w-[clamp(20px,4vw,40px)] h-[clamp(20px,4vw,40px)] object-contain"
      />
      <span>POT: {potAmount}</span>
    </div>
  );
}
