import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, resetError } from '../../../redux/features/auth/authSlice';
import googleLogo from '../assets/google.png';
import facebookLogo from '../assets/facebook.png';
import myLittleRiverLogo from '../../../assets/images/black-logo.png';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate for redirection

  const { isLoading, error, token } = useSelector((state) => state.auth);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    // Reset previous errors and messages
    dispatch(resetError());
    setMessage('');

    // Dispatch the loginUser action
    dispatch(loginUser({ email, password }));
  };

  useEffect(() => {
    // Redirect to /dashboard if login is successful (i.e., token is present)
    if (token) {
      navigate('/dashboard');
    }
  }, [token, navigate]);

  const handleGoogleLogin = () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    window.location.href = `${apiBaseUrl}/api/auth/google`;
  };
  
  const handleFacebookLogin = () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    window.location.href = `${apiBaseUrl}/api/auth/facebook`;
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-white">
      <div className="w-full max-w-sm p-8">
        <div className="flex justify-center mb-8">
          <img src={myLittleRiverLogo} alt="My Little River Logo" className="h-32" />
        </div>        
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <input 
              type="email" 
              id="email" 
              className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-[#009249]"
              placeholder="Enter email" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="mb-6 relative">
            <input 
              type={showPassword ? "text" : "password"} 
              id="password" 
              className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-[#009249]"
              placeholder="Password" 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <div 
              className="absolute inset-y-0 right-0 pr-4 flex items-center cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>

          {message && <div className="mb-4 text-blue-500 text-center">{message}</div>}
          {/* {error && <div className="mb-4 text-red-500 text-center">{error}</div>} */}
          {error && <div className="mb-4 text-red-500 text-center">Site closed for maintenance</div>}


          <button 
            type="submit" 
            className="w-full bg-[#009249] text-white py-3 px-4 rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-[#009249] flex items-center justify-center"
            disabled={isLoading} // Disable button while loading
          >
            {isLoading ? (
              <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-white border-t-transparent"></div>
            ) : (
              'Login'
            )}
          </button>
        </form>

        <div className="text-center mt-4">
          <p className="text-sm text-gray-600">
            Don't have an Account? < Link to='/register' className='text-[#009249] hover:underline focus:outline-none' style={{textDecoration:'none'}}>Register</Link>
          </p>
        </div>

        <div className="flex items-center my-6">
          <hr className="flex-grow border-gray-400" style={{ height: '1px', backgroundColor: '#474042' }} />
          <span className="mx-4 text-gray-500">OR</span>
          <hr className="flex-grow border-gray-400" style={{ height: '1px', backgroundColor: '#474042' }} />
        </div>

        <div className="flex justify-center space-x-6">
          <button onClick={handleGoogleLogin}>
            <img src={googleLogo} alt="Google" className="w-10 h-10" />
          </button>
          <button onClick={handleFacebookLogin}>
            <img src={facebookLogo} alt="Facebook" className="w-10 h-10" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
