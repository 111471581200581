import React from 'react';

export default function CommunityCards({ communityCards }) {
  return (
    <div className="absolute top-[40%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex gap-[clamp(8px,2vw,20px)]">
      {communityCards.map((cardSrc, i) => (
        <div
          key={i}
          className="w-[clamp(60px,6vw,100px)] h-[clamp(90px,9vw,150px)]
                     border border-green-400 rounded-md bg-[#014201]
                     flex items-center justify-center"
        >
          <img
            src={cardSrc}
            alt={`Community Card ${i + 1}`}
            className="w-[90%] h-[90%] object-contain rounded"
          />
        </div>
      ))}
    </div>
  );
}
