// MLRCoinSection.js
import React, { useState, useEffect, useCallback } from 'react';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

function MLRCoinSection() {
  const { publicKey } = useWallet();
  const { connection } = useConnection();
  const [tokenBalance, setTokenBalance] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [conversionAmount, setConversionAmount] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Your MLRcoin token mint address
  const tokenMintAddress = "mntGi4FroWrmTRGg8Xi96DFMiaJ8mDSgTZUGdAcwBoR";

  // Function to fetch token balance from the blockchain
  const fetchTokenBalance = useCallback(async () => {
    if (!publicKey) {
      setTokenBalance(null);
      return;
    }
    try {
      const mintPublicKey = new PublicKey(tokenMintAddress);
      // Fetch token accounts owned by the connected wallet for the given mint address
      const tokenAccounts = await connection.getTokenAccountsByOwner(publicKey, {
        mint: mintPublicKey,
      });
      if (tokenAccounts.value.length > 0) {
        // Assume the first token account holds the token balance you need
        const accountPubkey = tokenAccounts.value[0].pubkey;
        const balanceResult = await connection.getTokenAccountBalance(accountPubkey);
        setTokenBalance(balanceResult.value.uiAmount);
      } else {
        setTokenBalance(0);
      }
    } catch (error) {
      console.error("Error fetching token balance:", error);
      setTokenBalance(0);
    }
  }, [connection, publicKey, tokenMintAddress]);

  useEffect(() => {
    fetchTokenBalance();
  }, [fetchTokenBalance]);

  // Opens the conversion modal after checking if user has enough points
  const handleOpenConvertModal = () => {
    const availablePoints = parseInt(localStorage.getItem('balance')) || 0;
    if (availablePoints < 10) {
      setErrorMessage("Not enough points to convert. Minimum required is 10.");
      setTimeout(() => setErrorMessage(""), 3000);
      return;
    }
    setShowModal(true);
  };

  const handleModalCancel = () => {
    setShowModal(false);
    setConversionAmount("");
  };

  const handleModalConvert = () => {
    const availablePoints = parseInt(localStorage.getItem('balance')) || 0;
    const amount = parseInt(conversionAmount);
    if (isNaN(amount) || amount < 10) {
      setErrorMessage("Please enter a valid amount (minimum 10).");
      setTimeout(() => setErrorMessage(""), 3000);
      return;
    }
    if (amount > availablePoints) {
      setErrorMessage("You don't have enough points.");
      setTimeout(() => setErrorMessage(""), 3000);
      return;
    }
    // For simulation, assume 1 point converts to 1 MLRcoin.
    const newTokenBalance = (tokenBalance || 0) + amount;
    setTokenBalance(newTokenBalance);
    const newPoints = availablePoints - amount;
    localStorage.setItem('balance', newPoints);
    setShowModal(false);
    setConversionAmount("");
    setErrorMessage(""); // Clear any error
  };

  return (
    <div className="relative my-8 p-8 rounded-xl shadow-2xl bg-gradient-to-r from-indigo-700 to-purple-900 text-white overflow-hidden">
      {/* Animated background shapes */}
      <div className="absolute top-0 right-0 w-64 h-64 bg-purple-400 rounded-full mix-blend-screen opacity-50 blur-3xl animate-pulse"></div>
      <div className="absolute bottom-0 left-0 w-64 h-64 bg-indigo-400 rounded-full mix-blend-screen opacity-50 blur-3xl animate-ping"></div>

      <div className="relative z-10 flex flex-col md:flex-row items-center justify-between">
        {/* Left: Coin Image */}
        <div className="flex-shrink-0 mb-6 md:mb-0 md:mr-8">
          <img
            src="MLRCOIN -O.png"
            alt="MLRcoin"
            className="w-40 h-40 md:w-52 md:h-52 object-contain"
          />
        </div>

        {/* Right: Content Area */}
        <div className="flex-grow text-center md:text-left">
          <h2 className="text-3xl md:text-4xl font-bold mb-2">MLRcoin Hub</h2>
          <p className="text-base md:text-lg mb-6">
            Ready to boost your earnings? Connect your wallet to manage your MLRcoin, convert points into crypto, and keep track of your daily balance.
          </p>

          {publicKey ? (
            <div>
              <div className="mb-4 text-base md:text-2xl break-words">
                <span className="font-semibold">Wallet Address:</span>{" "}
                <span className="font-extrabold block max-w-xs truncate">
                  {publicKey.toBase58()}
                </span>
              </div>
              <div className="mb-4 text-base md:text-2xl">
                <span className="font-semibold">MLRcoin Balance:</span>{" "}
                <span className="font-extrabold">
                  {tokenBalance !== null ? tokenBalance : 'Loading...'}
                </span>
              </div>
              <div className="flex flex-col sm:flex-row gap-4 justify-center md:justify-start">
                <button
                  className="bg-white text-indigo-700 font-bold py-2 px-4 rounded hover:bg-gray-100 transition duration-300"
                  onClick={() => window.open('https://www.mlrcoin.com', '_blank')}
                >
                  Buy MLRcoin
                </button>
                <button
                  className="bg-white text-indigo-700 font-bold py-2 px-4 rounded hover:bg-gray-100 transition duration-300"
                  onClick={handleOpenConvertModal}
                >
                  Convert Points
                </button>
              </div>
              {errorMessage && (
                <p className="mt-4 text-red-500 font-medium text-center">{errorMessage}</p>
              )}
            </div>
          ) : (
            <div className="flex justify-center">
              {/* Inline style overrides for the WalletMultiButton */}
              <style>{`
                .wallet-adapter-button {
                  background: linear-gradient(90deg, #f7a440 0%, #f76363 100%) !important;
                  color: #ffffff !important;
                  font-weight: 600 !important;
                  padding: 0.75rem 1.5rem !important;
                  border: none !important;
                  border-radius: 0.5rem !important;
                  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
                  transition: background 0.3s ease, transform 0.3s ease;
                }
                .wallet-adapter-button:hover {
                  transform: translateY(-2px);
                  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.2);
                }
                /* If you want to change the text on the button itself: */
                .wallet-adapter-button:not([disabled]):hover .wallet-adapter-button-text {
                  opacity: 0.9;
                }
              `}</style>
              <WalletMultiButton />
            </div>
          )}
        </div>
      </div>

      {/* Conversion Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
            <h3 className="text-xl font-bold mb-4 text-indigo-700">Convert Points to MLRcoin</h3>
            <p className="mb-2 text-gray-700">
              Available Points: {parseInt(localStorage.getItem('balance')) || 0}
            </p>
            <input
              type="number"
              value={conversionAmount}
              onChange={(e) => setConversionAmount(e.target.value)}
              className="w-full border border-gray-300 rounded px-3 py-2 mb-4 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder="Enter points to convert (min 10)"
            />
            <div className="flex justify-end space-x-2">
              <button
                onClick={handleModalCancel}
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 transition duration-200"
              >
                Cancel
              </button>
              <button
                onClick={handleModalConvert}
                className="bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700 transition duration-200"
              >
                Convert
              </button>
            </div>
            {errorMessage && (
              <p className="mt-4 text-red-500 font-medium text-center">{errorMessage}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default MLRCoinSection;
